
import { defineComponent } from 'vue';

import {
  OrderPostPaymentActions
} from "../../components";

export default defineComponent({
  components: {
    OrderPostPaymentActions
  },

  props: ['orderId'],

  beforeRouteLeave(_,__,next) {
    localStorage.removeItem('payment_order')
    next(true)
  },
})
